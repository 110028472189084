<template>
  <div>
    <Header />
    <div class="home-slider">
      <div class="container p-l-r">
        <div class="col-md-12 col-sm-12">
          <a href="javascript:void(0)" v-on:click="$router.go(-1)" class="back-text">
            <img :src="imgBackarrow" width="25" height="25" /> {{ $t("new.Back") }}
          </a>
        </div>
      </div>
    </div>
    <section class="green-gradient-bg"></section>
    <section class="category-page">
      <div class="container">
        <div class="row h-100">
          <div
            class="col-md-4 col-sm-4 posRel mb-4 my-auto"
            style="cursor: pointer"
            v-for="(data, index) in categoryList"
            :key="index"
            v-on:click="$router.push('/categories/' + data.slug)"
          >
            <img
              :src="data.image ? data.image : CategoryPlaceholder1"
              class="img-fluid cate-img"
            />
            <div class="category-content" v-if="!data.image">
              <div class="category-name">{{ data.name }}</div>
            </div>
          </div>
        </div>
        <ErrorComponent @retry="getCategoryList(0)" ref="errorComponent" />
      </div>
    </section>
    <div class="container mb-3">
      <div class="row">
        <div class="col-md-12">
          <div class="page-bottom-pagination">
            <Pagination
              @page-change="pageChange"
              @items-per-page-change="itemsPerPageChange"
              ref="listPagination"
            />
          </div>
        </div>
      </div>
    </div>

    <Blog />
    <Footer />
  </div>
</template>

<script setup>
import CategoryPlaceholder1 from "../../../public/static/img/CategoryPlaceholder.png";
import imgBackarrow from "../../../public/static/img/back-arrow.svg";
</script>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Blog from "@/components/Blog";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination.vue";
import $ from "jquery";
export default {
  name: "MainCategory",
  components: { Header, Footer, Blog, ErrorComponent, Pagination },
  data() {
    return {
      loader: true,
      categoryList: [],
      itemsPerPage: "12",
      keyword: this.$route.query.keyword,
      searchObj: {
        offset: "",
        order_by: "ASC",
        sort_by: "name",
        uuid: "",
      },
    };
  },
  mounted() {
    this.getMetaContent();
    this.getCategoryList(0);
  },
  methods: {
    getMetaContent() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/metaContent/detail/1",
        })
        .then((res) => {
          document.title = res.title;
          $("meta[property='og:title']").attr("content", res.title);
          $("meta[property='og:description']").attr("content", res.description);

          var metaTag = document.getElementsByTagName("meta");

          for (var i = 0; i < metaTag.length; i++) {
            if (metaTag[i].name == "description") {
              metaTag[i].content = res.description;
            }
          }
        })
        .catch(() => {});
    },
    pageChange(offset) {
      this.getCategoryList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getCategoryList(0);
    },
    getCategoryList(offset) {
      this.loader = true;
      if (this.$refs.errorComponent) {
        this.$refs.errorComponent.updateListLoader(true);
      }
      if (offset == 0) {
        this.categoryList = [];
      }
      if (offset) {
        this.searchObj.offset = offset;
        this.searchObj.limit = this.itemsPerPage;
      }
      if (this.keyword) {
        this.searchObj.keyword = this.keyword;
      }
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/category/pagination/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = true;
          for (var i in res.list) {
            res.list[i].name = this.$helperService.getName(res.list[i].name);
          }
          this.categoryList = res.list;
          this.searchObj.offset = res.next_offset;
          if (offset == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count, this.itemsPerPage);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              res,
              this.$i18n.t("product Sub Category.Category not available")
            );
            this.$refs.errorComponent.updateListLoader(false);
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
  },
};
</script>

