<template>
  <div>
    <Header />
    <div class="home-slider">
      <div class="container p-l-r">
        <div
          id="myCarousel"
          class="carousel slide"
          data-bs-ride="carousel"
          data-interval="3000"
        >
          <ul class="carousel-indicators">
            <li
              data-bs-target="#myCarousel"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></li>
            <li
              data-bs-target="#myCarousel"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></li>
            <li
              data-bs-target="#myCarousel"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></li>
          </ul>
          <div class="carousel-inner">
            <div
              class="carousel-item"
              v-for="(result, index) in bannerList"
              :class="index == 0 ? 'active' : ''"
              :key="index"
            >
              <div class="row h-100">
                <div class="col-md-4 col-sm-4 my-auto order-md-1 order-2">
                  <div class="round-box mobile-step">
                    <h1>{{ result.title }}</h1>
                    <p>
                      {{ result.description }}
                    </p>
                    <button class="btn button" @click="openurl(result.redirect_url)">
                      <a
                        :href="result.redirect_url"
                        style="text-decoration: none; color: white"
                      >
                      {{ $t("new.Explore More") }}</a
                      >
                      <img :src="imgWhitearrow" width="20" height="20" />
                    </button>
                  </div>
                </div>
                <div class="col-md-8 col-sm-8 my-auto order-md-2 order-1">
                  <img
                    :src="result.image ? result.image : bannerPlaceholder"
                    class="img-fluid left-img-curve"
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="green-gradient-bg"></section>
    <section class="product-categories single-cate" v-if="categoryCount > 0">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 py-4 pb-none">
            <h2 class="h2-title pt-15">{{ $t("productCategory.Product Categories") }}</h2>
          </div>
        </div>
        <div class="d-none d-sm-block">
          <div class="row posRel">
            <div
              class="col-md-4 col-sm-4 mb-4 posRel cursor"
              v-on:click="$router.push('/categories/' + categoryList[0].slug)"
            >
              <img
                :src="categoryList[0].image ? categoryList[0].image : CategoryPlaceholder"
                class="cate-img"
              />
              <div class="category-content" v-if="!categoryList[0].image">
                <div class="category-name">{{ categoryList[0].name }}</div>
              </div>
            </div>
            <div class="col-md-8 col-sm-8">
              <div class="row">
                <template v-for="(data, index) in categoryList" :key="index">
                  <div
                    class="col-md-6 col-sm-6 mb-4 posRel cursor"
                    v-if="index > 0"
                    v-on:click="$router.push('/categories/' + data.slug)"
                  >
                    <img
                      :src="data.image ? data.image : CategoryPlaceholderSM"
                      class="object-fit-contain img-fluid"
                      height="225"
                    />
                    <div class="category-content" style="top: 36%" v-if="!data.image">
                      <div class="category-name">{{ data.name }}</div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <!-- Mobile Product Categories slider here -->
        <div class="row d-block d-sm-none">
          <div class="col-lg-12 col-sm-12 product-category-slider1">
            <div
              v-for="(data, index) in categoryList"
              :key="index"
              class="mb-4 cursor"
              v-on:click="$router.push('/categories/' + data.slug)"
            >
              <img
                :src="data.image ? data.image : CategoryPlaceholder2"
                class="product-cate-img"
              />
            </div>
          </div>
        </div>
        <div class="col-md-12 col-sm-12 pt-15 txt-right">
          <a href="javascript:void(0)" v-on:click="$router.push('/main-categories')"
            >{{ $t("new.View All Categories") }} <img :src="imgGarrow" width="20" height="20"
          /></a>
        </div>
      </div>
    </section>

    <section class="brands-section" v-if="brandCount > 0">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 py-4 pb-none">
            <h2 class="h2-title">{{ $t("brand.Brands") }}</h2>
          </div>
          <div class="d-none d-sm-block">
            <div class="row">
              <div
                class="col-md-3 col-sm-3 col-6 mb-3 posRel text-center cursor"
                v-for="(data, index) in brandList"
                :key="index"
                v-on:click="$router.push('/brand-detail/' + data.slug)"
              >
                <img
                  :src="data.image ? data.image : brandPlaceholder1"
                  class="img-fluid custom-width"
                />
                <div class="brand-name" v-if="!data.image">{{ data.name }}</div>
              </div>
            </div>
          </div>
          <!-- Mobile Brands slider here -->
          <div class="row d-block d-sm-none">
            <div class="col-lg-12 col-sm-12 brands-slider">
              <div v-for="(data, index) in brandList"
                :key="index"
                v-on:click="$router.push('/brand-detail/' + data.slug)"> 
                <img :src="data.image ? data.image : brandPlaceholder1" class="img-fluid" width="100%" />
                <div class="brand-name" v-if="!data.image">{{ data.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12 col-sm-12 pt-15 txt-right">
            <a href="javascript:void(0)" v-on:click="$router.push('/brands')"
              >{{ $t("new.View All Brands") }}
              <img :src="imgGarrow" width="20" height="20" style="border: 0"
            /></a>
          </div>
        </div>
      </div>
    </section>
    <section class="highest-rated-section" v-if="ratedCount > 0">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 py-4 pb-none">
            <h2 class="h2-title">{{ $t("home.Highest Rated") }}</h2>
          </div>
        </div>
        <div class="d-none d-sm-block">
          <div class="row h-100">
            <div
              class="col-md-4 col-sm-4 mb-4"
              v-on:click="gotoPage('RATED', ratedList[0], $event)"
            >
              <div class="white-box">
                <div class="text-center cursor">
                  <div>
                    <img
                      :src="ratedList[0].image ? ratedList[0].image : productPlaceholder"
                      class="img-contain1"
                    />
                  </div>
                  <div class="my-auto">
                    <div class="product-title">{{ ratedList[0].name }}</div>
                    <div class="text-center single-product">
                      <star-rating
                        id="expert-list"
                        class="mb-1"
                        :increment="0.5"
                        :rating="ratedList[0].avg_rating"
                        :show-rating="false"
                        :star-size="15"
                        :read-only="true"
                      ></star-rating>
                    </div>
                  </div>
                </div>
                <a href="javascript:void(0)" class="posAbs">
                  <img
                    data-name="FAV"
                    v-if="ratedList[0].is_favorite == '0'"
                    :src="imgHeart"
                    width="20"
                    height="20"
                  />
                  <img
                    data-name="FAV"
                    v-if="ratedList[0].is_favorite == '1'"
                    :src="imgFillHeart"
                    width="20"
                    height="20"
                  />
                </a>
              </div>
            </div>
            <div class="col-md-8 col-sm-8">
              <div class="row">
                <template v-for="(data, index) in ratedList" :key="index">
                  <div
                    class="col-md-6 col-sm-6 mb-4"
                    v-if="index > 0"
                    v-on:click="gotoPage('RATED', data, $event)"
                  >
                    <div class="white-box">
                      <div class="d-flex text-lf cursor">
                        <div>
                          <img
                            :src="data.image ? data.image : productPlaceholder2"
                            class="img-contain"
                          />
                        </div>
                        <div class="my-auto">
                          <div class="product-title">
                            {{ data.name }}
                          </div>
                          <div class="text-center">
                            <star-rating
                              id="expert-list"
                              class="mb-1 text-center"
                              :increment="0.5"
                              :rating="data.avg_rating"
                              :show-rating="false"
                              :star-size="15"
                              :read-only="true"
                            ></star-rating>
                          </div>
                        </div>
                      </div>
                      <a href="javascript:void(0)" class="posAbs">
                        <img
                          :src="imgHeart"
                          data-name="FAV"
                          v-if="data.is_favorite == '0'"
                          width="20"
                          height="20"
                        />
                        <img
                          data-name="FAV"
                          v-if="data.is_favorite == '1'"
                          :src="imgFillHeart"
                          width="20"
                          height="20"
                        />
                      </a>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <!-- Mobile Highest Rated slider here -->
        <div class="d-block d-sm-none">
          <div class="row h-100 highest-rated-slider">
            <div
              class="col-md-4 col-sm-4 mb-4"
              v-for="(data, index) in ratedList"
              :key="index"
              v-on:click="$router.push('/product/' + data.slug)"
            >
              <div class="white-box">
                <div class="d-flex text-lf cursor">
                  <div>
                    <img
                      :src="data.image ? data.image : productPlaceholder2"
                      class="img-contain"
                    />
                  </div>
                  <div class="my-auto">
                    <div class="product-title">{{ data.name }}</div>
                    <div class="text-left">
                      <star-rating
                        id="expert-list"
                        class="mb-1 text-center"
                        :increment="0.5"
                        :rating="data.avg_rating"
                        :show-rating="false"
                        :star-size="15"
                        :read-only="true"
                      ></star-rating>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row mt-4">
          <div class="col-md-12 col-sm-12 txt-right">
            <a href="javascript:void(0)">
              View All Products
              <img :src="imgGarrow" width="20" height="20" style="border: 0"
            /></a>
          </div>
        </div> -->
      </div>
    </section>

    <section class="highest-rated-section" v-if="lastViewedCount > 0">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-sm-12 py-4 pb-none">
            <h2 class="h2-title">{{ $t("home.Last Viewed Products") }}</h2>
          </div>
        </div>
        <div class="d-none d-sm-block">
          <div class="row h-100">
            <div
              class="col-md-4 col-sm-4 mb-4"
              v-on:click="gotoPage('LAST', lastViewedList[0], $event)"
            >
              <div class="white-box">
                <div class="d-flex text-lf cursor">
                  <div>
                    <img
                      :src="
                        lastViewedList[0].image
                          ? lastViewedList[0].image
                          : productPlaceholder
                      "
                      class="img-contain"
                    />
                  </div>
                  <div class="my-auto">
                    <div class="product-title">
                      {{ lastViewedList[0].name }}
                    </div>
                    <div class="text-center">
                      <star-rating
                        id="expert-list"
                        class="mb-1 text-center"
                        :increment="0.5"
                        :rating="lastViewedList[0].avg_rating"
                        :show-rating="false"
                        :star-size="15"
                        :read-only="true"
                      ></star-rating>
                    </div>
                  </div>
                </div>
                <a href="javascript:void(0)" class="posAbs">
                  <img
                    :src="imgHeart"
                    data-name="FAV"
                    v-if="lastViewedList[0].is_favorite == '0'"
                    width="20"
                    height="20"
                  />
                  <img
                    data-name="FAV"
                    v-if="lastViewedList[0].is_favorite == '1'"
                    :src="imgFillHeart"
                    width="20"
                    height="20"
                  />
                </a>
              </div>
            </div>
            <div class="col-md-8 col-sm-8">
              <div class="row">
                <template v-for="(data, index) in lastViewedList" :key="index">
                  <div
                    class="col-md-6 col-sm-6 mb-4"
                    v-if="index > 0"
                    v-on:click="gotoPage('LAST', data, $event)"
                  >
                    <div class="white-box">
                      <div class="d-flex justify-content-between text-lf cursor">
                        <div>
                          <img
                            :src="data.image ? data.image : productPlaceholder2"
                            class="img-contain"
                          />
                        </div>
                        <div class="my-auto">
                          <div class="product-title">
                            {{ data.name }}
                          </div>
                          <div class="text-left">
                            <star-rating
                              id="expert-list"
                              class="mb-1 text-center"
                              :increment="0.5"
                              :rating="data.avg_rating"
                              :show-rating="false"
                              :star-size="15"
                              :read-only="true"
                            ></star-rating>
                          </div>
                        </div>
                      </div>
                      <a href="javascript:void(0)" class="posAbs">
                        <img
                          :src="imgHeart"
                          data-name="FAV"
                          v-if="data.is_favorite == '0'"
                          width="20"
                          height="20"
                        />
                        <img
                          data-name="FAV"
                          v-if="data.is_favorite == '1'"
                          :src="imgFillHeart"
                          width="20"
                          height="20"
                        />
                      </a>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <!-- Mobile Highest Rated slider here -->
        <div class="d-block d-sm-none">
          <div class="row h-100 highest-rated-slider">
            <div
              class="col-md-4 col-sm-4 mb-4"
              v-for="(data, index) in lastViewedList"
              :key="index"
              v-on:click="$router.push('/product/' + data.slug)"
            >
              <div class="white-box">
                <div class="d-flex text-lf cursor">
                  <div>
                    <img
                      :src="
                        lastViewedList[0].image
                          ? lastViewedList[0].image
                          : productPlaceholder2
                      "
                      class="img-contain"
                    />
                  </div>
                  <div class="my-auto">
                    <div class="product-title">{{ data.name }}</div>
                    <div class="text-left pt-3">
                      <star-rating
                        id="expert-list"
                        class="mb-1 text-center"
                        :increment="0.5"
                        :rating="data.avg_rating"
                        :show-rating="false"
                        :star-size="15"
                        :read-only="true"
                      ></star-rating>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row mt-4">
          <div class="col-md-12 col-sm-12 txt-right">
            <a href="javascript:void(0)">
              View All Products
              <img :src="imgGarrow" width="20" height="20" style="border: 0"
            /></a>
          </div>
        </div> -->
      </div>
    </section>

    <Recommendation />

    <Blog />

    <Newsletter />

    <Footer />
  </div>
</template>
<script setup>
import imgWhitearrow from "../../../public/static/img/white-arrow.svg";
import bannerPlaceholder from "../../../public/static/img/banner.png";
import CategoryPlaceholder from "../../../public/static/img/CategoryPlaceholder.png";
import CategoryPlaceholderSM from "../../../public/static/img/CategoryPlaceholderSM.png";
import CategoryPlaceholder2 from "../../../public/static/img/CategoryPlaceholder2.png";
import productPlaceholder from "../../../public/static/img/productPlaceholder.png";
import productPlaceholder2 from "../../../public/static/img/ProductPlaceholder2.png";
import imgGarrow from "../../../public/static/img/green-arrow.svg";
import brandPlaceholder1 from "../../../public/static/img/brandPlaceholderBlank.svg";
import imgHeart from "../../../public/static/img/heart.png";
import imgFillHeart from "../../../public/static/img/fill-heart.png";
</script>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Blog from "@/components/Blog";
import Recommendation from "@/components/Recommendation";
import Newsletter from "@/components/Newsletter";
import StarRating from "vue-star-rating";
import $ from "jquery";
export default {
  name: "HomePage",
  components: { Header, Footer, Blog, Newsletter, StarRating, Recommendation },
  data() {
    return {
      itemsPerPage: 10,
      bannerList: [],
      brandList: [],
      categoryList: [],
      ratedList: [],
      recommendationList: [],
      lastViewedList: [],
      bannerCount: 0,
      brandCount: 0,
      categoryCount: 0,
      ratedCount: 0,
      recommendationCount: 0,
      lastViewedCount: 0,
      searchObj: {
        keyword: "",
        offset: 0,
      },
      token: localStorage.getItem("access_token"),
    };
  },
  computed: {
    user() {
      return this.$storeService.getters.getUser;
    },
  },

  watch: {
    user(newValue) {
      console.log(newValue);
      if (localStorage.getItem("access_token")) {
        this.getLastViewedList(0);
        this.getRatedList(0);
      }
    },
  },
  mounted() {
    this.getMetaContent();
    this.getBannerList();
    if (this.token) {
      this.getLastViewedList(0);
    }
  },
  methods: {
    getBannerList() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/banner/pagination/list",
        })
        .then((res) => {
          this.bannerList = res.list;
          this.bannerCount = res.count;
          this.getCategoryList(0);
        })
        .catch(() => {});
    },
    openurl(url) {
      if (url) {
        // window.open(url, '_blank');
      }
    },
    getMetaContent() {
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/metaContent/detail/3",
        })
        .then((res) => {
          document.title = res.title;
          $("meta[property='og:title']").attr("content", res.title);
          $("meta[property='og:description']").attr("content", res.description);

          var metaTag = document.getElementsByTagName("meta");

          for (var i = 0; i < metaTag.length; i++) {
            if (metaTag[i].name == "description") {
              metaTag[i].content = res.description;
            }
          }
        })
        .catch(() => {});
    },
    getCategoryList(offset) {
      if (offset) {
        this.searchObj.offset = offset;
        // this.searchObj.limit = this.itemsPerPage;
      }
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/category/pagination/list?limit=5&is_featured=1",
          _body: this.searchObj,
        })
        .then((res) => {
          for (var i in res.list) {
            res.list[i].name = this.$helperService.getName(res.list[i].name);
          }
          this.categoryCount = res.count;
          this.categoryList = res.list;
          this.getBrandList(0);
        })
        .catch(() => {});
    },
    getBrandList(offset) {
      if (offset) {
        this.searchObj.offset = offset;
        // this.searchObj.limit = this.itemsPerPage;
      }
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/brand/pagination/list?limit=4&is_featured=1",
          _body: this.searchObj,
        })
        .then((res) => {
          for (var i in res.list) {
            res.list[i].name = this.$helperService.getName(res.list[i].name);
          }
          this.brandCount = res.count;
          this.brandList = res.list;
          this.getRatedList(0);
        })
        .catch(() => {});
    },
    getRatedList(offset) {
      if (offset) {
        this.searchObj.offset = offset;
        this.searchObj.limit = this.itemsPerPage;
      }
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/highest/rated/product/list?limit=5",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          for (var i in res.list) {
            res.list[i].name = this.$helperService.getName(res.list[i].name);
          }
          this.ratedCount = res.count;
          this.ratedList = res.list;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, " ");
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
          }
        });
    },
    getLastViewedList(offset) {
      if (offset) {
        this.searchObj.offset = offset;
        this.searchObj.limit = this.itemsPerPage;
      }
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/recent/viewed/product/list?limit=3",
          _body: this.searchObj,
        })
        .then((res) => {
          for (var i in res.list) {
            res.list[i].name = this.$helperService.getName(res.list[i].name);
          }
          this.lastViewedCount = res.count;
          this.lastViewedList = res.list;
          this.getRecommendation(0);
        })
        .catch(() => {});
    },
    getRecommendation(offset) {
      if (offset) {
        this.searchObj.offset = offset;
        this.searchObj.limit = this.itemsPerPage;
      }
      this.$api
        .webRequest({
          _method: "GET",
          _action: "/recommended/product/list?limit=3",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          for (var i in res.list) {
            res.list[i].product.name = this.$helperService.getName(
              res.list[i].product.name
            );
          }
          this.recommendationCount = res.count;
          this.recommendationList = res.list;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    gotoPage: function (type, data, event) {
      var action = event.target.getAttribute("data-name");
      if (action && action == "FAV") {
        this.addFavorite(type, data);
      } else {
        this.$router.push("/product/" + data.slug);
      }
    },
    addFavorite(type, data) {
      if (!localStorage.getItem("access_token")) {
        this.$storeService.commit("setOpenLoginPopupFlag", true);
      } else {
        var obj = {};
        obj.product_id = data.id;
        this.$api
          .webRequest({
            _method: "POST",
            _action: "/favourite",
            _body: obj,
          })
          .then((res) => {
            this.$toast.success(res.message, { position: "top-right" });
            if (type == "RECOMM") {
              this.getRecommendation(0);
            }
            if (type == "LAST") {
              this.getLastViewedList(0);
            }
            if (type == "RATED") {
              this.getRatedList(0);
            }
          });
      }
    },
  },
};
</script>

